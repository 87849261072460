.machine_item{
  background: #ffffff1f;
  transition: all ease .3s;
  cursor: pointer;
  &:hover {
    background: #326bfb66;
  }
}

.desc {
  line-height: 10px;
  font-size: 8px;
  font-weight: 300;
  margin-top: 4px;
}