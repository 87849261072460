@import "./_normalize.css";
@import "./animation.css";
@import "./fonts.scss";

html,
body{
  font-family: system-ui;
  user-select: text;
  padding: 0 32px;
  background-color: #000;
  color: #fff;
}

div{
  box-sizing: border-box;
}

.input-placeholder{
  &::placeholder{
    color: #C9D2D988 !important;
  }
}