.tweet {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  caret-color: #fff;
  color: #fff;
  height: 140px !important;
  border-radius: 18px;
  color: #fff;
  font-size: 20px !important;
  padding: 16px !important;
  &::placeholder {
    color: #aaa !important;
    font-size: 20px;
  }
}

.range_picker{
  cursor: pointer;
  :global{
    input{
      cursor: pointer;
      &::placeholder{
        color: #03FFF9 !important;
      }
    }
    .ant-picker-suffix{
      color: #03FFF9;
    }
  }
}

.scroll_bar{
  padding-right: 10px;
  &::-webkit-scrollbar{
    width: 8px;
  }
  &::-webkit-scrollbar-track{
    background: #00000000;
  }
  &::-webkit-scrollbar-thumb{
    background: #ffffff66;
    border-radius: 10px;
  }
}